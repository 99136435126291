import anime from 'animejs/lib/anime.es.js'
import { parseQueryString } from './queryString' 

export function getDefaultBannerOffset() {
    return document.querySelector('.nav-banner').getBoundingClientRect().height
    // return 0
}

export function scrollToHrefTarget(hash) {
    if(typeof hash !== 'undefined' && hash != "" && hash.indexOf('#target=') != -1){
        let params = parseQueryString(hash);
        if(typeof params.target != 'undefined'){
            params.offset = params.offset || getDefaultBannerOffset();
            params.focusAfter = params.focusAfter || null;
            return animeScroll(decodeURIComponent(params.target), params.offset, params.focusAfter);
        }
    }
    return false;
}

export function scrollToElementTarget(hash) {
    let offset = getDefaultBannerOffset();
    return animeScroll(hash, offset);
}

export function animeScroll(hash, offset = 0, focusAfter = null){
    let el = false;

    try{
        el = document.querySelector(hash);
    } catch(e) {
        el = false;
    }

    if(el){
        let scroll_offset = window.pageYOffset + el.getBoundingClientRect().top - offset;
        
        // calculate duration against distance 
        // to make speed always the same
        let diff = Math.abs(window.pageYOffset - scroll_offset);
        let timeSpeed = diff * 0.5;


        if(window.scrollAnim) {
            window.scrollAnim.pause()
        }

        window.scrollAnim = anime({
            targets: 'html,body',
            scrollTop: scroll_offset,
            duration: timeSpeed,
            easing: 'cubicBezier(.56,.01,.28,1)',
            complete: function(){
                let loc = '#target=' + encodeURIComponent(hash) + "&offset=" + offset;
                if(focusAfter){
                    if(document.querySelector(focusAfter)){
                        document.querySelector(focusAfter).focus();
                        loc += "&focusAfter=" + focusAfter;
                    }
                }

                window.location.hash = loc;
                el.classList.add('targeted-once');
            }
        });

        return true;
    }

    return false;
}

window.addEventListener('wheel', () => {
    if(window.scrollAnim) {
        window.scrollAnim.pause()
    }
})
