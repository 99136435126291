/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

/**
 * Real JS initialization
 */
import "./utils/setAttributes";
import { setDashoffset } from "./utils/svgLength";
import {
    getDefaultBannerOffset,
    scrollToElementTarget,
    scrollToHrefTarget,
    animeScroll,
} from "./utils/animatedScroll";
import { parseQueryString, createQueryString } from "./utils/queryString";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import { debounce } from "alpinejs/src/utils/debounce";
import { nextTick } from "alpinejs/src/nextTick";
import anime from "animejs/lib/anime.es.js";
import Splitting from "splitting";

/**
 * Alpine Plugins
 */
Alpine.plugin(intersect);
Alpine.plugin(focus);

/**
 * Custom stagger directive
 */
Alpine.directive(
    "stagger-parent",
    (
        el,
        { value, modifiers, expression },
        { Alpine, effect, cleanup, evaluate, evaluateLater }
    ) => {
        // If existing data directive, we merge our data with it
        let starterData = { __stagger_shown: false };
        let float = parseFloat(expression);
        if (el.hasAttribute("x-data")) {
            let existingData = evaluateLater(el.getAttribute("x-data"));
            starterData = {
                ...existingData,
                ...starterData,
            };
        }

        // We set x-intersect early so that the official plugin can kick in at the right time
        el.setAttributes({
            "x-data": JSON.stringify(starterData),
            "x-intersect": "__stagger_shown = true",
        });

        let splitters = el.querySelectorAll("[x-split]");
        const resetWordSplit = (ev) => {
            let parentEl = ev.target.closest("[x-split]");
            if (
                ev.propertyName == "opacity" ||
                ev.propertyName == "transform"
            ) {
                if (parentEl.dataset && parentEl.dataset.originalhtml) {
                    parentEl.innerHTML = parentEl.dataset.originalhtml;
                    delete parentEl.dataset.originalhtml;
                    parentEl.removeAttribute("style");
                }
            }
            ev.target.removeEventListener("transitionend", resetWordSplit);
        };

        if (splitters) {
            for (let i = 0, il = splitters.length; i < il; ++i) {
                let splitter = splitters[i];

                splitter.dataset.originalhtml = splitter.innerHTML;
                let splitterEls = Splitting({ target: splitter, by: "chars" });

                for (let j = 0, jl = splitterEls[0].chars.length; j < jl; ++j) {
                    let word = splitterEls[0].chars[j];
                    let wordDelay = Math.min(
                        800 / splitterEls[0].chars.length,
                        float
                    );

                    word.setAttributes({
                        "x-stagger": "_",
                        "x-stagger-override-delay": wordDelay,
                    });

                    if (j == splitterEls[0].chars.length - 1) {
                        if (
                            typeof splitter.dataset.reset == "undefined" ||
                            (splitter.dataset.reset &&
                                splitter.dataset.reset != "false")
                        ) {
                            word.addEventListener(
                                "transitionend",
                                resetWordSplit
                            );
                        }
                    }
                }
            }
        }

        // We get all children with x-stagger and set transitionDelay amount + x-transition properties
        let staggerElements = el.querySelectorAll("[x-stagger]");

        const removeTransitionDelay = (ev) => {
            // let remove = false;
            let elParent = ev.target.closest("[x-stagger-parent]");
            if (!elParent || !Alpine.$data(elParent).__stagger_shown) {
                return;
            }

            // // We make sure it's on our property change... some CSS transition elsewhere keep triggering it somehow... 🙄
            // if(['path', 'circle', 'line', 'polyline'].includes(ev.target.tagName.toLowerCase()) && ev.propertyName == 'stroke-dashoffset') {
            //     remove = true;
            // } else if(ev.target.classList.contains('word') && ev.propertyName == 'height'){
            //     remove = true;
            // } else if(ev.propertyName == 'opacity') {
            //     remove = true;
            // }

            // if(remove) {
            ev.target.style.transitionDelay = "";
            ev.target.removeEventListener(
                "transitionend",
                removeTransitionDelay
            );
            // }
        };

        /**
         * Note: Impossible d'utiliser x-show sinon il y a un "bump" si on rafraîchit alors que la page est déjà scrollée
         * dû au fait que x-show met "display:none" initialement
         *
         * on utilise x-cloack sur toute la page : on doit mettre nextTick sinon les lignes svg ne peuvent être calculées
         */
        nextTick(() => {
            for (let i = 0, il = staggerElements.length; i < il; ++i) {
                let staggerElement = staggerElements[i];
                let extraDelay = parseFloat(
                    staggerElement.getAttribute("x-stagger-delay")
                );
                // console.log(staggerElement, extraDelay);
                if (!extraDelay) {
                    extraDelay = 0;
                }
                let delay = float;

                let overrideDelay = parseFloat(
                    staggerElement.getAttribute("x-stagger-override-delay")
                );
                if (!Number.isNaN(overrideDelay)) {
                    delay = overrideDelay;
                }

                staggerElement.style.transitionDelay =
                    i * delay + extraDelay + "ms";

                if (
                    ["path", "circle", "line", "polyline"].includes(
                        staggerElement.tagName.toLowerCase()
                    )
                ) {
                    staggerElement.setAttributes({
                        "stroke-dashoffset": setDashoffset(staggerElement),
                        ":class": `{
                        '!stroke-dashoffset-0 transition-stroke':__stagger_shown
                    }`,
                    });
                } else if (staggerElement.classList.contains("word")) {
                    staggerElement.setAttributes({
                        ":style": `{
                        'height': ${
                            staggerElement.getBoundingClientRect().height
                        } + 'px'
                    }`,
                        ":class": `{
                        '!opacity-100 !translate-y-0':__stagger_shown,
                        '!h-0':!__stagger_shown
                    }`,
                    });
                } else if (
                    staggerElement.classList.contains("stagger-left") ||
                    staggerElement.classList.contains("stagger-right")
                ) {
                    staggerElement.setAttributes({
                        ":class": `{
                        '!opacity-100 !translate-x-0':__stagger_shown
                    }`,
                    });
                } else {
                    staggerElement.setAttributes({
                        ":class": `{
                        '!opacity-100 !translate-y-0':__stagger_shown
                    }`,
                    });
                }
                /* else if(staggerElement.tagName.toLowerCase() == 'circle') {
                staggerElement.setAttributes({
                    ':class': `{
                        '!scale-150 transition-mask':__stagger_shown
                    }`
                })
            } */

                staggerElement.addEventListener(
                    "transitionend",
                    removeTransitionDelay,
                    true
                );
            }
        });
    }
);

// Alpine components
Alpine.data("application", () => ({
    navOpened: false,
    internalNavSelected: null,
    // default value to avoid errors because of data inheritance
    // sometimes this attribute shows an error
    __stagger_shown: false,
    // Scroll direction
    lastSt: 0,
    anchor: 0,
    direction: 0,
    debouncedDirection: 0,
    moving: false,
    appLoaded: false,
    st: 0,
    init() {
        const debouncedChangeDirection = debounce((dir) => {
            this.debouncedDirection = dir;
        }, 1000);

        this.$watch("direction", (val) => {
            this.anchor = this.st;
            debouncedChangeDirection(val);
            // console.log(val);
        });
        this.$watch("navOpened", (val) => {
            if (val) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        });
        document.addEventListener("swup:popState", () => {
            this.saveprev();
        });

        window.addEventListener("load", () => {
            // Scroll on load if we have a hash
            var hash = window.location.hash;
            scrollToHrefTarget(hash);
            document.documentElement.classList.add("loaded");
        });

        // Add auto resizing to textareas
        let textareas = document.querySelectorAll("textarea");
        for (let i = 0, il = textareas.length; i < il; ++i) {
            let textarea = textareas[i];
            textarea.style.height = "50px";
            textarea.addEventListener("input", (ev) => {
                ev.target.style.height = "5px";
                ev.target.style.height = ev.target.scrollHeight + "px";
            });
        }

        // all links that have target in them
        let scrollableLinks = document.querySelectorAll("[href*='#target']");
        for (let i = 0, il = scrollableLinks.length; i < il; ++i) {
            let scrollableLink = scrollableLinks[i];
            scrollableLink.addEventListener("click", (ev) => {
                let res = scrollToHrefTarget(ev.currentTarget.hash);
                // if same page, we prevent otherwise click through
                if (res) {
                    ev.preventDefault();
                }
            });
        }

        this.$nextTick(() => {
            this.appLoaded = true;
        });

        // Add first of line and last classes to elements
        let line_parents = document.querySelectorAll('.js-lines');
        let nav_banner = document.querySelector('.nav-banner');

        const resizeObserver = new ResizeObserver((entries) => {
            for (let i=0, il=entries.length; i < il; ++i) {
                let entry = entries[i]
                this.setLines(entry.target, 'Observer');
            }
        });

        // const navResizeObserver = new ResizeObserver((entries) => {
        //     for (let i=0, il=entries.length; i < il; ++i) {
        //         let entry = entries[i]
        //         let wrap_width = parseInt(entry.target.querySelector('.nav-banner-wrap')offsetWidth)
        //         let total_width = entry.target.querySelector('.banner-logo').offsetWidth + entry.target.querySelector('.nav-banner-inner').offsetWidth;
        //     }
        // });

        for (let i=0, il=line_parents.length; i < il; ++i) {
            let line_parent = line_parents[i]
            resizeObserver.observe(line_parent);
        }
        // navResizeObserver.observe(nav_banner);

        // // Not needed?
        // window.addEventListener('load', () => {
        //     for (let i=0, il=line_parents.length; i < il; ++i) {
        //         let line_parent = line_parents[i]
        //         this.setLines(line_parent, 'Load');
        //     }
        // });
        
        // const date = new Date(Date.now() - 5 * 60 * 1000);
        // const date = new Date(Date.now() - 1440 * 60 * 1000);
        // const formattedDate = date.toISOString();

        // fetch('/api', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'x-craft-gql-cache': 'no-cache',
        //         'accept': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         query: `
        //             query MyQuery {
        //               formieSubmissions(form: "reservation", dateCreated: "> ${formattedDate}") {
        //                 ... on reservation_Submission {
        //                   vehicleId: fleetmanVehicleId
        //                   startDate: dateFrom
        //                   endDate: dateTo
        //                   pickup {
        //                     ... on shop_default_Entry {
        //                       fleetmanLocationId
        //                     }
        //                   }
        //                   dropoff {
        //                     ... on shop_default_Entry {
        //                       fleetmanLocationId
        //                     }
        //                   }
        //                   mail: email
        //                   message
        //                   firstName
        //                   lastName
        //                 }
        //               }
        //             }
        //         `
        //     })
        // }).then((res) => res.json())
        // .then((result) => {
        //     let submissions = result.data.formieSubmissions;
        //     if(submissions && submissions.length) {
        //         for (let i=0, il=submissions.length; i < il; ++i) {
        //             submissions[i].startLocationId = submissions[i].dropoff[0].fleetmanLocationId;
        //             submissions[i].endLocationId = submissions[i].pickup[0].fleetmanLocationId;
        //             submissions[i].subject = 'Temporary test for reservations on  from rent-a-boxer.com';
        //             submissions[i].time = '8:00';
        //             submissions[i].startDate = new Date(Date.parse(submissions[i].startDate)).toISOString().replace('Z', '+01:00');
        //             submissions[i].endDate = new Date(Date.parse(submissions[i].endDate)).toISOString().replace('Z', '+01:00');
        //             delete submissions[i].dropoff;
        //             delete submissions[i].pickup;
        //             // delete submissions[i].endDate;
        //             // delete submissions[i].startDate;
        //             console.log('submitting', submissions[i]);
        //             fetch('http://h2980003.stratoserver.net:8081/fleetmanapi/createLead', {
        //                 method: 'POST',
        //                 headers: {
        //                     'Content-Type': 'application/json',
        //                     'Authorization': 'Bearer d7ed5dcf-60e8-4340-a17f-8a36eda0d49d',
        //                 },
        //                 body: JSON.stringify(submissions[i])
        //             }).then((leadRes) => leadRes.json())
        //             .then((leadResult) => {
        //                 console.log('created?', leadResult);
        //             })
        //         }
        //     }
        // });
        
        /**
         * Remove ?switch_lang from url on load
         */
        if (window.location.search.includes("?switch_lang")) {
            let qs = parseQueryString(window.location.search);
            delete qs.switch_lang;
            let new_qs = createQueryString(qs);
            window.history.replaceState({}, "", window.location.pathname + new_qs);
        }
    },
    handleScroll() {
        this.st =
            window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop +
                ((document.documentElement &&
                    document.documentElement.scrollTop) ||
                    0);

        // this.direction = Math.min(Math.max(-1, this.st - this.lastSt), 1);
        this.direction = this.st - this.lastSt > 0 ? 1 : -1;

        if (Math.abs(this.st - this.anchor) > 200) {
            this.moving = this.direction;
        } else {
            this.moving = 0;
        }
        this.lastSt = this.st;
    },
    setLines(el, context='') {
        let line_els = el.querySelectorAll('.js-set-line');

        let line = 0;
        let ycoord = -1;
        for (let j=0, jl=line_els.length; j < jl; ++j) {
            let line_el = line_els[j]
            line_el.classList.remove('line-first', 'line-last');
            if(line_el.getBoundingClientRect().bottom > ycoord) {
                ycoord = line_el.getBoundingClientRect().bottom;
                line++;
                line_el.classList.add('line-first');
                if(line_els[j-1]) {
                    line_els[j-1].classList.add('line-last');
                }
            }
            line_el.setAttribute('data-line', line);
            line_els[line_els.length-1].classList.add('line-last');
        }
    },
    scrollTo($event) {
        if (window.scrollAnim) {
            window.scrollAnim.pause();
        }

        const hash = this.$el.getAttribute('href');
        const result = scrollToElementTarget(hash);

        if (result) {
            $event.preventDefault();
        }
    },
}));

/**
 * Animations on scroll
 */

Alpine.data("visibleProgress", (params = {}) => ({
    // Options
    absolute: false,
    offset: 0,
    lerpRatio: 1,
    loaded: false,
    animatedElements: [],
    parentElement: null,
    visible: false,
    id: -1,

    // Element measurements
    elTop: 0,
    elHeight: 0,
    yOffset: 0,
    wHeight: 0,

    // Different progress values
    raw: 0,
    progress: 0,
    top: 0,
    lerp: 0,

    // requestAniamtionFrames identifiers
    scrollframe: -1,
    lerpFrame: -1,
    init() {
        // Allow overriding the main element with a ref named "root"
        // Otherwise use the magic $root if it exists since lots of people add it
        // Otherwise use the $el where init is called, which is the root element if nothing else
        if (typeof this.$refs.root != "undefined") {
            this.parentElement = this.$refs.root;
        } else if (typeof this.$root != "undefined") {
            this.parentElement = this.$root;
        } else {
            this.parentElement = this.$el;
        }

        /**
         * Avoid painting scroll progress if page isn't loaded
         * Images and fonts will be changing sizes of things when loaded
         */
        // window.addEventListener('load', () => {
        //     this.loaded = true;
        // });
        /**
         * @todo Vérifier si ça tient toujours de mettre this.loaded sur un événement load
         *
         * layout shifts devraient être moins communs avec l'auto calcul
         * des aspect ratio des images maintenant dans les navigateur smodernes.
         * Et peut-être même que vu qu'on recalcule au load plus tard anyways,
         * on devrait peut-être pas bloquer l'initialisation des valeurs
         * ou tout simplement les recalculer
         */
        this.$nextTick(() => {
            this.loaded = true;

            // // safari 12 bug
            // if(window.browserInfos.name == 'safari' && window.browserInfos.versionNumber < 13 ) {
            //     window.setTimeout(() => {
            //         this.startCalculation()
            //     }, 500);
            // }else{
            //     this.startCalculation()
            // }

            // if(window.browserInfos.name == 'safari') {
            //     document.documentElement.classList.add('safari');
            // }
        });

        // this.$watch('section', (val) => {
        //     console.log(val);
        // })

        /**
         * Bind different events that trigger animation progress
         */
        window.addEventListener("scroll", () => {
            this.startCalculation();
        });
        window.addEventListener("resize", () => {
            this.startCalculation();
        });
        window.addEventListener("load", () => {
            this.startCalculation();
        });
    },
    animate(animeParams = {}) {
        let defaults = {
            autoplay: false,
            duration: 5000,
            easing: "linear",
            targets: this.$el,
            ...animeParams,
        };

        this.$el.animation = anime(defaults);

        this.animatedElements.push(this.$el);
    },
    startCalculation() {
        if (this.scrollframe == -1) {
            let rect = this.parentElement.getBoundingClientRect();
            this.elTop = rect.top;
            this.elHeight = rect.height;
            this.yOffset = window.pageYOffset;
            this.wHeight = window.innerHeight;

            this.scrollframe = window.requestAnimationFrame(() => {
                this.calculateVisibleProgress();
            });
        }
    },
    calculateVisibleProgress() {
        let startPos = this.elTop;
        startPos = startPos === 0 ? 0.000001 : startPos; // Avoid division by zero
        let endPos = this.wHeight;

        this.top = 1 - startPos / endPos;

        if (this.absolute !== false && typeof this.absolute == "number") {
            this.raw = (this.yOffset - this.offset) / this.absolute;
            this.progress = Math.min(Math.max(this.raw, 0), 0.9999);
        } else {
            startPos = this.wHeight - this.elTop;
            endPos = this.wHeight + this.elHeight;
            this.raw = (startPos - this.offset) / endPos;
            this.progress = Math.min(Math.max(this.raw, 0), 0.9999);
        }

        /**
         * Do not trigger lerp if isn't loaded,
         * lerpRatio is to its default,
         * the progress is not "inside" the animation
         */
        if (
            this.loaded &&
            this.lerpRatio !== 1 &&
            this.progress >= 0.000001 &&
            this.progress <= 0.9999
        ) {
            this.updateLerp();
        }

        /**
         * Update animations
         */
        if (this.loaded) {
            this.animateProgress();
        }

        this.scrollframe = -1;
    },
    animateProgress() {
        for (let i = 0, il = this.animatedElements.length; i < il; ++i) {
            let element = this.animatedElements[i];

            let mode = element.dataset.mode || "progress";

            let theanim = element.animation;
            theanim.seek(theanim.duration * this[mode]);
        }

        if (this.progress > 0 && this.progress < 1) {
            this.visible = true;
        } else {
            this.visible = false;
        }

        this.$dispatch("progress", this);
    },
    updateLerp() {
        if (this.lerp == -1) {
            this.lerp = this.progress;
        }

        let diff = this.progress - this.lerp;

        this.lerp += (this.progress - this.lerp) * this.lerpRatio;
        this.animateProgress();

        // We stop the animation before reaching absolute value otherwise
        // it runs infinitely because of the nature of lerp calculation
        if (Math.abs(diff) > 0.002) {
            this.lerpFrame = window.requestAnimationFrame(() => {
                this.updateLerp();
            });
        } else if (this.lerpFrame !== -1) {
            window.cancelAnimationFrame(this.lerpFrame);
            this.lerpFrame = -1;
        }
    },
    ...params,
}));

Alpine.data("accordion", (params = {}) => ({
    opened: false,
    init() {
        this.setHeight();
    },
    setHeight() {
        this.$nextTick(() => {
            this.$refs.body.style.height = "";
            this.$refs.body.style.height = this.$refs.body.scrollHeight + "px";
        });
    },
    ...params,
}));

Alpine.data("pricingDetails", (params = {}) => {
    document.querySelector('[data-fui-id="reservation-total"]').setAttributes({
        "x-model": "priceTotalStr",
    });

    return {
        openedPopups: [],
        priceTable: [],
        totalField: null,
        priceTotal: 0,
        priceSubtotal: 0,
        priceTotalStr: "",
        priceSubtotalStr: "",
        dateFrom: null,
        dateTo: null,
        priceTotalError: "",
        priceSavings: 0,
        priceSavingsStr: "",
        error: false,
        days: 1,
        kmTotalStr: "",
        init() {
            this.totalField = this.$el.querySelector(
                '[data-fui-id="reservation-total"]'
            );
            this.priceTotal = this.priceTable[0].price;
            this.priceSubtotal = this.priceTable[0].price;
            this.priceSavings = 0;
            this.priceTotalStr = this.priceTotal + "€";
            this.priceSubtotalStr = this.priceSubtotal + "€";
            this.priceSavingsStr = this.priceSavings + "€";
            this.kmTotalStr = this.priceTable[0].km + ' km';

            this.$watch("priceTotal", () => {
                this.priceTotalStr = this.priceTotal + "€";
            });
            this.$watch("priceSubtotal", () => {
                this.priceSubtotalStr = this.priceSubtotal + "€";
            });
            this.$watch("priceSavings", () => {
                this.priceSavingsStr = -1 * this.priceSavings + "€";
            });
            this.$watch("days", () => {
                if(this.days > this.priceTable.length) {
                    this.kmTotalStr = this.priceTable[0].km + ' km';
                } else {
                    this.kmTotalStr = this.priceTable[this.days - 1].km + ' km';
                }
            });

            ['change', 'keyup', 'blur', 'click', 'submit'].forEach((eventName) => {
                this.$el
                .addEventListener(eventName, (ev) => {
                    let newFromDate = null;
                    let newToDate = null;

                    if(event.target.matches('[data-fui-id="reservation-dateFrom"]')) {
                        newFromDate = flatpickr.parseDate(ev.target.value, 'd/m/Y');
                    }
                    if(event.target.matches('[data-fui-id="reservation-dateTo"]')) {
                        newToDate = flatpickr.parseDate(ev.target.value, 'd/m/Y');
                    }
                    if(event.target.matches('[data-field-handle="dateFrom"] input[type="hidden"]')) {
                        newFromDate = new Date(event.target.value);
                    }
                    if(event.target.matches('[data-field-handle="dateTo"] input[type="hidden"]')) {
                        newToDate = new Date(event.target.value);
                    }

                    if(newFromDate != null) {
                        this.changeFromDate(newFromDate);
                    }
                    if(newToDate != null) {
                        this.changeToDate(newToDate);
                    }
                });
            });
        },
        changeFromDate(newFromDate){
            if(typeof newFromDate != 'undefined' && !isNaN(newFromDate.getTime())) {
                this.dateFrom = newFromDate;
                this.calculateDays();
            }
        },
        changeToDate(newToDate){
            if(typeof newToDate != 'undefined' && !isNaN(newToDate.getTime())) {
                this.dateTo = newToDate;
                this.calculateDays();
            }
        },
        open(override_selector = "") {
            let id_selector = this.$el.getAttribute("href");
            if (
                typeof override_selector == "string" &&
                override_selector !== ""
            ) {
                id_selector = override_selector;
            }

            if (document.querySelector(id_selector)) {
                this.openedPopups.push(id_selector);
            }
        },
        close(override_selector = "") {
            let id_selector = this.$el.getAttribute("href");
            if (
                typeof override_selector == "string" &&
                override_selector !== ""
            ) {
                id_selector = override_selector;
            }

            if (this.openedPopups.includes(id_selector)) {
                this.openedPopups = this.openedPopups.filter(
                    (item) => item !== id_selector
                );
            }
        },
        calculateDays() {
            if (
                this.dateFrom && typeof this.dateFrom == 'object' && !isNaN(this.dateFrom.getTime()) &&
                this.dateTo && typeof this.dateTo == 'object' && !isNaN(this.dateTo.getTime()) &&
                this.dateTo - this.dateFrom >= 0
            ) {
                this.days = Math.ceil(
                    (this.dateTo - this.dateFrom) / (1000 * 3600 * 24)
                ) + 1;
                // console.log(this.dateFrom, this.dateTo, this.days);
                if (this.days > this.priceTable.length) {
                    this.priceTotalStr = this.priceTotalError;
                    this.priceSavings = 0;
                    this.error = true;
                } else {
                    this.priceTotal = this.priceTable[this.days - 1].price;
                    this.priceSubtotal = this.priceTable[0].price * this.days;
                    this.priceSavings = this.priceSubtotal - this.priceTotal;
                    this.error = false;
                }
            }
        },
        ...params,
    };
});

Alpine.data("detectScrollPassed", (params = {}) => {
    return {
        passed: false,
        lastOffsetTop: 0,
        init() {
            this.lastOffsetTop = this.$el.offsetTop;
            this.$watch('st', () => {
                this.passed = this.$el.offsetTop != this.lastOffsetTop;

                this.lastOffsetTop = this.$el.offsetTop;
            })
        },
        ...params,
    };
});
