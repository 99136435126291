/**
 * Parse Query string
 * @param  String queryString   URL formatted query string with or without first param
 * @return Object               Return the object of params
 */
export function parseQueryString( queryString ) {
    var params = {}, queries, temp, i, l;
    queryString = queryString.slice(1);
    queries = queryString.split("&");

    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }

    return params;
}
/**
 * Create a query string from an objec of params
 * @todo  add a param for mapping params to url path (ex.: /page/2/ instead of ?paged=2s)
 * @param  object    params
 * @return string    url
 */
export function createQueryString( params ) {
    let url = '';
    if(params.paged) {
        url+='page/'+ params.paged+'/';
        delete params.paged;
    }

    if(params.length > 0) {
        url+= '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')
    }

    return url;
}