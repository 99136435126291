/**
 * These functions are based on anime.js functions, but with some fixes:
 * 1) Fix the order of execution in getTotalLength for compatibility with complex shapes
 * 2) Calculate correctly length regardless of resized SVG
 * 3) Adjust the rounding (/1000*1000) for precision compatibility and smoothness of animations in different browsers
 */

export function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function getCircleLength(el) {
    return Math.PI * 2 * el.getAttribute('r');
}

export function getRectLength(el) {
    return el.getAttribute('width') * 2 + el.getAttribute('height') * 2;
}

export function getLineLength(el) {
    return getDistance(
        { x: el.getAttribute('x1'), y: el.getAttribute('y1') },
        { x: el.getAttribute('x2'), y: el.getAttribute('y2') }
    );
}

export function getPolylineLength(el) {
    var points = el.points;
    var totalLength = 0;
    var previousPos;
    for (var i = 0; i < points.numberOfItems; i++) {
        var currentPos = points.getItem(i);
        if (i > 0) {
            totalLength += getDistance(previousPos, currentPos);
        }
        previousPos = currentPos;
    }
    return totalLength;
}

export function getPolygonLength(el) {
    var points = el.points;
    return getPolylineLength(el) + getDistance(points.getItem(points.numberOfItems - 1), points.getItem(0));
}

// Path animation

export function getTotalLength(el) {
    switch (el.tagName.toLowerCase()) {
        case 'circle':
            return getCircleLength(el);
        case 'rect':
            return getRectLength(el);
        case 'line':
            return getLineLength(el);
        case 'polyline':
            return getPolylineLength(el);
        case 'polygon':
            return getPolygonLength(el);
    }
    if (el.getTotalLength) {
        return el.getTotalLength();
    }
}


export function getAdjustedTotalLength(el) {
    // Calculate Ratio of rendered size over real size over its definitive bounding box
    let ratio = 1;
    if(el.getBoundingClientRect().width) {
        // Lines can have width of zero
        ratio = el.getBoundingClientRect().width/el.getBBox().width;
    }else if(el.getBoundingClientRect().height) {
        ratio = el.getBoundingClientRect().height/el.getBBox().height;
    }

    return getTotalLength(el) * ratio;
}

export function setDashoffset(el) {
    let pathLength = 0;
    if(el.getAttribute('vector-effect') == 'non-scaling-stroke' || el.getAttribute('vector-effect') == 'non-scaling-size') {
        pathLength = getAdjustedTotalLength(el);
    }else{
        pathLength = getTotalLength(el);
    }

    pathLength = Math.round(pathLength * 1000) / 1000;
    el.setAttribute('stroke-dasharray', pathLength);
    return pathLength;
}
